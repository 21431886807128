import window from 'window';
import { hasDebug, components } from 'App';

/** @type {Boolean} Is local debug active? */
const localDebug = true;

/** @type {Boolean} Is debug active? */
const hasLocalDebug = hasDebug() && localDebug;

/** @type {Object} Debug helper */
const debug = components.Debugger(hasLocalDebug, 'Helpers');

/** @type {Function} Log helper bound to window */
export const log = debug.log.bind(window);

export default log;
