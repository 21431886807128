import { prestashop } from 'window';
import { i18n, components } from 'App';
import { log } from './utils/debug';

import globalFetch from '~/utils/fetch';

/**
 * Default fetch options
 * @type {Object}
 */
const defaultOptions = {
  url: '/',
  dataType: 'json',
  method: 'GET',
  data: {
    fc: 'module',
    module: 'meta_endpointsmanager',
    controller: 'endpoints',
    /* eslint-disable-next-line */
    id_lang: i18n.id,
  },
};

/**
 * Local fetch function mergin custom and local default options
 *
 * @param  {Object}  options The custom options to pass to the function
 * @return {Promise}         A promise resolving on done, rejecting on fail
 */
async function fetch(dataOptions, skipErrors = false) {
  let response = null;

  try {
    response = await globalFetch({
      ...defaultOptions,
      data: {
        ...defaultOptions.data,
        ...dataOptions,
      },
    });
  } catch (err) {
    return response;
  }

  if (!response) {
    components.alert.error(i18n.l('There was an unexpected error, try again later.'));
    return response;
  }

  if (response.code !== 200 && !skipErrors) {
    if (response.messages.length) {
      components.alert.error(response.messages.join('<br>'), 0);
    }
    return null;
  }

  if (response.result.static_token) {
    prestashop.modules.meta_frontsettings.CONFIG.STATIC_TOKEN = response.result.static_token;
  }

  if (response.result.token) {
    prestashop.modules.meta_frontsettings.CONFIG.TOKEN = response.result.token;
  }

  return response;
}

export default {
  /**
   * Get the attributes for the given product
   *
   * @param  {Vuex}   store The current store instance
   * @return {Object}       The attributes of the product
   */
  async getProductAttributesGroups(store, id) {
    log('action', 'getAttributesGroups', id);

    /* eslint-disable camelcase */
    const response = await fetch({
      action: 'product',
      method: 'getAttributesGroups',
      id_product: id,
    });

    return response;
  },

  /**
   * Get the tags for the given product
   *
   * @param  {Vuex}   store The current store instance
   * @return {Object}       The tags of the product
   */
  async getProductTags(store, id) {
    log('action', 'getTags', id);

    /* eslint-disable camelcase */
    const response = await fetch({
      action: 'product',
      method: 'getTags',
      id_product: id,
    });

    return response;
  },

  /**
   * Get datalayer for the given product
   *
   * @param  {Vuex}   store The current store instance
   * @param  {Number}   id product id. 
   * @param  {Number}   index Position of product in list
   * @return {Object}       The tags of the product
   */
  async getProductDatalayer(store, id, index) {
    log('action', 'getDatalayer', id);

    /* eslint-disable camelcase */
    const response = await fetch({
      action: 'product',
      method: 'getDatalayer',
      id_product: id,
      index: index
    });

    return response;
  },
};
