import * as types from './mutation-types';
import { log } from './utils/debug';

export default {
  /**
   * Update the breakpoint if it has changed
   *
   * @param  {Object} state      The current state
   * @param  {String} breakpoint The new breakpoint
   * @return {void}
   */
  [types.SET_BREAKPOINT](state, breakpoint) {
    if (breakpoint !== state.breakpoint) {
      log('mutation', types.SET_BREAKPOINT, breakpoint);
      state.breakpoint = breakpoint;
    }
  },
};
