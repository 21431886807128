import { addEventListener } from 'window';
import App, { store } from 'App';
import actions from './actions';
import state from './state';
import getters from './getters';
import mutations from './mutations';
import * as types from './mutation-types';

const helpers = {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};

store.registerModule('helpers', helpers);

// Update breakpoint on window debounced resize
let timer = null;
addEventListener('resize', () => {
  clearTimeout(timer);
  timer = setTimeout(() => {
    store.commit(`helpers/${types.SET_BREAKPOINT}`, App.breakpoint);
  }, 300);
});
