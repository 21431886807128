/* =============================================================================
 * Cart mutation types
 * ========================================================================== */

/**
 * Set breakpoint
 * @type {String}
 */
export const SET_BREAKPOINT = 'SET_BREAKPOINT';

export default {
  SET_BREAKPOINT,
};
